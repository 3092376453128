
import { Options, Vue, Watch } from "vue-property-decorator";
import classCatalogue from "./classcatalogue.vue";
import * as api from "@/api/courselearn";

@Options({
  components: {
    classCatalogue,
  },
})
export default class extends Vue {
  private active = 1;
  private classDetail = {};
  private classList: any[] = [];
  private player = null;

  @Watch("classDetail")
  onclassDetailChanged(newValue: string, oldValue: string) {
    const that = this as any;
    if (newValue) {
      this.$nextTick(() => {
        // console.log(this.$route.query.coverURL)
        if (this.$route.query.playAuth) {
          this.player = new Aliplayer({
            id: "J_prismPlayer",
            autoplay: false,
            height: "500px",
            vid: this.$route.query.videoId,
            playauth: this.$route.query.playAuth,
            cover: this.$route.query.coverURL,
          });
          that.player.on("ended", () => {
            console.log("视频播放完毕");
            api
              .videoFinish({
                classId: Number.parseInt(this.$route.query.id as any),
                itemId: Number.parseInt(this.$route.query.itemId as any),
                projectId: this.$store.state.project.projectinfo.projectId,
              })
              .then((res: any) => {
                if (res.code === 0) {
                  const itemId = Number.parseInt(
                    this.$route.query.itemId as any
                  );
                  let index = 0;
                  for (let i = 0; i < that.classList.length; i++) {
                    if (itemId === that.classList[i].id) {
                      index = i;
                      break;
                    }
                  }
                  that.$set(that.classList[index], "isFinish", 1);
                }
              });
          });
          that.player.on("error", function (e: any) {
            // 隐藏
            // document.getElementsByClassName('prism-ErrorMessage')[0].style.display = 'none'
            // console.log(document.getElementsByClassName('prism-ErrorMessage')[0])
            // Dialog.confirm({
            //   title: '身份认证过期',
            //   message: '是否重新认证？'
            // })
            //   .then(() => {
            //     // on confirm
            //     that.$router.push({name: 'identify', query: {videoId: that.$route.query.videoId, id: that.$route.query.id, classId: that.classdata.id, itemId: that.$route.query.itemId}})
            //   })
            //   .catch(() => {
            //     // on cancel
            //   })
            // // 解析
            // var errorData = e.paramData
            // console.dir(errorData)
          });
        }
      });
    }
  }

  mounted() {
    this.getClassData();
  }

  getClassData() {
    api
      .classinfo({
        classId: this.$route.query.id,
        projectId: this.$store.state.project.projectinfo.projectId,
      })
      .then((res: any) => {
        if (res.code === 0) {
          this.classDetail = res.data.class;
          this.classList = res.data.items;
        } else {
          this.$toast.fail(res.msg);
        }
      });
  }
}
